<template>
  <div>
    <Navbar />
    <div style="background-color: #f5f2f4">
      <div class="formContainer">
        <form
          style="background-color: white"
          class="pt-3 pl-3 pr-3 pb-4"
          @submit.prevent="saveForm"
        >
          <PathPage class="pb-2" />
          <label class="title">
            <h5
              class="purple"
              style="font-family: mtavruliBOLD; text-transform: uppercase"
            >
              {{ $t("vacancyForm.thankVacancy") }}
            </h5>
            <p>
              {{ $t("vacancyForm.ganvixilavtKandidaturas") }}
            </p>
          </label>
          <div class="lineStyle"></div>
          <div class="row pt-3 mb-0">
            <div class="col-12 col-sm-12 mb-0">
              <label class="mb-0"
                >{{ $t("vacancyForm.vacancyFormName") }}
              </label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>

          <!-- <input v-model="form.name" placeholder="სახელი, გვარი" required /> -->

          <div class="col-12 col-sm-12 pl-0 pr-0 pt-3">
            <label class="mb-0"
              >{{ $t("vacancyForm.vacancyFormBirth") }}
            </label>
            <div class="row pt-1">
              <div class="col-12 col-sm-4">
                <select v-model="form.birthDate.day" class="form-control">
                  <option selected value="1">1</option>
                  <option
                    v-for="item in Array.from({ length: 30 }, (_, i) => i + 2)"
                    :value="item"
                    :key="item.day"
                  >
                    {{ item }}
                  </option>
                </select>
                <span style="text-transform: uppercase">{{
                  $t("vacancyForm.vacancyFormBirthDay")
                }}</span>
              </div>
              <div class="col-12 col-sm-4">
                <select v-model="form.birthDate.month" class="form-control">
                  <option selected :value="$t('months.January')">
                    {{ $t("months.January") }}
                  </option>
                  <option
                    v-for="item in monthList"
                    :value="item"
                    :key="item.month"
                  >
                    {{ item }}
                  </option>
                </select>
                <span style="text-transform: uppercase">{{
                  $t("vacancyForm.vacancyFormBirthMonth")
                }}</span>
              </div>
              <div class="col-12 col-sm-4">
                <select v-model="form.birthDate.year" class="form-control">
                  <option selected :value="new Date().getFullYear()">
                    {{ new Date().getFullYear() }}
                  </option>
                  <option
                    v-for="item in Array.from(
                      { length: 80 },
                      (_, i) => new Date().getFullYear() - i - 1
                    )"
                    :value="item"
                    :key="item.year"
                  >
                    {{ item }}
                  </option>
                </select>
                <span style="text-transform: uppercase">{{
                  $t("vacancyForm.vacancyFormBirthYear")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-group pt-3 mb-0 pb-0">
            <label class="mb-0" for="inputAddress2">
              <span
                style="text-transform: uppercase; font-family: dejavuBOLD"
                >{{ $t("vacancyForm.vacancyFormAddress") }}</span
              ></label
            >

            <input
              v-model="form.address"
              type="text"
              class="form-control"
              id="inputAddress2"
              placeholder=""
              required="required"
            />
          </div>

          <div class="row mb-0 pt-3">
            <div class="col-12 col-md-4 pt-3">
              <div class="form-group">
                <label class="mb-0" for="inputState">
                  {{ $t("vacancyForm.vacancyFormEducation") }}</label
                >
                <select v-model="form.educationType" class="form-control">
                  <option
                    selected
                    :value="$t('vacancyForm.educationTypes.middle')"
                  >
                    {{ $t("vacancyForm.educationTypes.middle") }}
                  </option>
                  <!-- <option selected :value="form.educationType">
                    {{ form.educationType }}
                  </option> -->
                  <option
                    v-for="item in educationType"
                    :value="item"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-8 pt-3">
              <div>
                <label class="mb-0">
                  {{ $t("vacancyForm.vacancyFormEducationName") }}</label
                >
                <input
                  v-model="form.educationName"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col mb-0 pl-0 pr-0 pt-3">
            <label class="switch mb-0">
              <span class="slider round dejavuBOLD">{{
                $t("vacancyForm.vacancyForWork")
              }}</span>
              <input
                class="ml-2"
                type="checkbox"
                v-model="isHaveWorkExperience"
              />
            </label>
            <div v-if="isHaveWorkExperience">
              <div
                v-for="(item, index) in form.workExperience"
                :key="item.index"
              >
                <div class="row pt-1">
                  <div class="col-12 col-sm-4">
                    <span
                      class="form-control"
                      style="text-transform: uppercasecol col-md-4"
                      placeholder="word"
                    >
                      {{ item.organization }}
                    </span>
                    <span>
                      {{ $t("vacancyForm.vacancyFormOrganization") }}</span
                    >
                  </div>

                  <div class="col-12 col-sm-4">
                    <span
                      style="text-transform: uppercase"
                      class="form-control"
                      placeholder="word"
                      >{{ item.position }}</span
                    >
                    <span> {{ $t("vacancyForm.vacancyFormPozition") }}</span>
                  </div>

                  <div class="col-12 col-sm-4">
                    <span
                      style="text-transform: uppercase"
                      class="form-control"
                      placeholder="word"
                      >{{ item.workPeriod }}</span
                    >
                    <span> {{ $t("vacancyForm.vacancyFormPeriod") }}</span>
                  </div>

                  <button
                    class="btn btn-primary mt-1"
                    type="button"
                    style=""
                    @click="deleteWorkExperience(index)"
                  >
                    {{ $t("front.delete") }}
                  </button>
                </div>
              </div>
              <div class="row pt-1">
                <div class="col-12 col-sm-4">
                  <input
                    class="form-control"
                    v-model="workExperienceFields.organization"
                    :required="
                      isHaveWorkExperience && form.workExperience.length <= 0
                    "
                  />
                  <span> {{ $t("vacancyForm.vacancyFormOrganization") }}</span>
                </div>
                <div class="col-12 col-sm-4">
                  <input
                    class="form-control"
                    v-model="workExperienceFields.position"
                    :required="
                      isHaveWorkExperience && form.workExperience.length <= 0
                    "
                  />
                  <span> {{ $t("vacancyForm.vacancyFormPozition") }}</span>
                </div>

                <div class="col-12 col-sm-4">
                  <select
                    class="form-control"
                    v-model="workExperienceFields.workPeriod"
                    selected="true"
                  >
                    <option selected value="0 - 3">0 - 3</option>
                    <option
                      class="form-control"
                      v-for="item in workExperiencePeriod"
                      :value="item"
                      :key="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <span> {{ $t("vacancyForm.vacancyFormPeriod") }}</span>
                </div>
              </div>

              <div class="button-div">
                <button
                  v-if="workExperienceFields.organization !== ''"
                  class="btn btn-primary"
                  type="button"
                  @click="addWorkExperience()"
                >
                  <span style="text-transform: uppercase">{{
                    $t("vacancyForm.vacancyFormAddExperience")
                  }}</span>
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 pl-0 pr-0 pb-0 pt-3">
            <label class="mb-0" for="inputEmail4">{{
              $t("vacancyForm.vacancyFormMail")
            }}</label>
            <input
              required
              v-model="form.email"
              type="email"
              class="form-control"
              id="inputEmail4"
            />
          </div>

          <div class="col-12 col-md-4 pl-0 pr-0 pt-3">
            <label class="mb-0">{{ $t("vacancyForm.vacancyFormPhone") }}</label>
            <input
              required
              v-model="form.phone"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-12 col-md-4 pl-0 pr-0 pb-0 pt-3">
            <label class="mb-0" for="">{{
              $t("vacancyForm.vacancyForeignLanguage")
            }}</label>
            <br />
            <span style="text-transform: uppercase" for="">
              {{ $t("vacancyForm.vacancyFormLevel") }} 1-5
            </span>

            <div
              style="row justify-content: start; align-items: center"
              v-for="(item, index) in form.languages"
              :key="item.index"
            >
              <div class="d-flex">
                <div class="form-control">{{ item.language }}</div>
                <div class="form-control">{{ item.level }}</div>
              </div>

              <button
                @click="deleteLanguage(index)"
                class="btn btn-primary mt-2"
                type="button"
              >
                {{ $t("vacancyForm.delete") }}
              </button>
            </div>
            <div
              style="display: flex; justify-content: start; align-items: center"
            >
              <input
                class="form-control"
                v-model="languagesFields.language"
                :required="
                  isHaveWorkExperience && form.workExperience.length <= 0
                "
              />

              <select
                v-model="languagesFields.level"
                selected="true"
                class="form-control"
              >
                <option selected value="1">1</option>
                <option
                  v-for="item in levelFields"
                  :value="item"
                  :key="item.level"
                >
                  {{ item }}
                </option>
              </select>
            </div>

            <div class="button-div pt-2">
              <button
                v-if="languagesFields.language != ''"
                type="button"
                class="btn btn-primary"
                @click="addLanguage()"
              >
                <span style="text-transform: uppercase">{{
                  $t("vacancyForm.vacancyFormAddLangugae")
                }}</span>
              </button>
            </div>
          </div>

          <div>
            <div class="col-12 col-md-4 pl-0 pr-0 pb-0 pt-3">
              <label class="mb-0" for="">{{
                $t("vacancyForm.vacancyFormOffice")
              }}</label>
              <br />
              <span style="text-transform: uppercase" for="">
                {{ $t("vacancyForm.vacancyFormLevel") }} 1-5
              </span>

              <div>
                <div class="div-container d-flex pt-2">
                  <span
                    style="text-transform: uppercase"
                    class="form-control"
                    placeholder="word"
                    >word</span
                  >
                  <b-form-select
                    v-model="form.officePrograms[0].level"
                    :options="levelOffice"
                    class="form-control"
                  ></b-form-select>
                </div>
              </div>
              <div>
                <div class="div-container d-flex pt-2">
                  <span style="text-transform: uppercase" class="form-control"
                    >excel</span
                  >
                  <b-form-select
                    v-model="form.officePrograms[1].level"
                    :options="levelOffice"
                    class="form-control"
                  ></b-form-select>
                </div>
              </div>
              <div>
                <div class="div-container d-flex pt-2">
                  <span style="text-transform: uppercase" class="form-control"
                    >powerpoint</span
                  >
                  <b-form-select
                    v-model="form.officePrograms[2].level"
                    :options="levelOffice"
                    class="form-control"
                  ></b-form-select>
                </div>
              </div>
              <div>
                <div class="div-container d-flex pt-2">
                  <span style="text-transform: uppercase" class="form-control"
                    >outlook</span
                  >
                  <b-form-select
                    v-model="form.officePrograms[3].level"
                    :options="levelOffice"
                    class="form-control"
                  ></b-form-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 pl-0 pr-0 pb-0 pr-0 pt-3">
            <label class="switch"
              >{{ $t("vacancyForm.vacancyFormCar") }}
              <select class="selectYes" v-model="form.haveCar">
                <option selected value="კი">{{ $t("vacancyForm.yes") }}</option>
                <option value="არა">{{ $t("vacancyForm.no") }}</option>
              </select>
            </label>
            <div v-if="form.haveCar == 'კი'">
              <input
                class="form-control"
                v-model="form.carModel"
                :placeholder="$t('vacancyForm.model')"
                required
              />
            </div>
          </div>

          <div class="col-12 col-sm-4 pl-0 pr-0 pt-3">
            <label class="mb-0">{{ $t("vacancyForm.vacancyName") }}</label>
            <input
              v-model="form.title"
              type="text"
              class="form-control"
              :placeholder="$t('vacancyForm.writeVacancyName')"
              required
            />
          </div>
          <div class="col-12 col-sm-4 pl-0 pr-0 pt-3">
            <label class="mb-0">{{
              $t("vacancyForm.howDidKnowVacancy")
            }}</label>
            <input
              v-model="form.vacancyFAQ"
              type="text"
              class="form-control"
              placeholder="
              "
            />
          </div>

          <div class="col-12 col-sm-4 pl-0 pr-0 pt-3">
            <label class="mb-0">{{ $t("vacancyForm.whenStartVacancy") }}</label>
            <input v-model="form.startJob" type="date" class="form-control" />
            <!-- <span class="mb-0">თარიღი</span> -->
          </div>

          <div
            class="mt-3 d-flex align-items-center justify-content-center"
            style="height: 250px; background-color: #f5f2f4"
          >
            <div class="form-group">
              <label class="mb-0 d-flex align-items-center" for="">
                <span class="mx-auto">
                  <img
                    class="mx-auto d-flex justify-content-center"
                    alt=""
                    :src="image"
                    width="100px"
                    height="100%"
                  />
                  <span class="mx-auto d-flex justify-content-center pb-3">{{
                    $t("vacancyForm.uploadPhoto")
                  }}</span>

                  <input
                    accept="image/*"
                    style="border: 1px solid #9ca1a5; width: 80%"
                    type="file"
                    class="
                      blackColor
                      form-control-file
                      d-flex
                      justify-content-center
                      mx-auto
                    "
                    hidden
                    id="exampleFormControlFile1"
                    @change="previewFiles"
                  />
                </span>
              </label>
            </div>
          </div>
          <div class="text-center mt-3">
            <b-spinner
              v-if="isLoading"
              variant="secondary"
              label="Text Centered"
            ></b-spinner>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <button
              type="submit"
              class="btn btn-primary"
              style="margin-top: auto; text-transform: uppercase"
            >
              {{ $t("front.send") }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";
import axios from "axios";
// import env from "../../../env.json";
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2";
import PathPage from "../../../views/UserPages/PathPage.vue";
import env from "../../../env.json";
import img from "../../../assets/icons/uploadToCloud.png";
export default {
  components: {
    Navbar,
    Footer,
    VueSweetalert2,
    PathPage,
  },
  name: "LanguageGenerator",
  data() {
    return {
      form: {
        name: "",
        title: "",
        address: "",
        educationName: "",
        birthDate: {
          day: 1,
          month: this.$t("months.January"),
          year: new Date().getFullYear(),
        },
        workExperience: [],
        languages: [],
        educationType: this.$t("vacancyForm.educationTypes.middle"),
        email: "",
        phone: "",
        startJob: "",
        haveCar: "კი",
        carModel: "",
        officePrograms: [
          { title: "Word", level: "" },
          { title: "Excel", level: "" },
          { title: "PowerPoint", level: "" },
          { title: "OutLook", level: "" },
        ],
        vacancyFAQ: "",
        image: null,
      },
      workExperienceFields: {
        organization: "",
        position: "",
        workPeriod: "0 - 3",
      },
      languagesFields: {
        language: "",
        level: "1",
      },
      isHaveWorkExperience: true,
      isCar: true,
      workExperiencePeriod: ["3 - 6", "8 - 12", "12 - 18", "18 - 24", "24 +"],
      levelFields: ["2", "3", "4", "5"],
      levelOffice: ["1", "2", "3", "4", "5"],
      monthList: [
        this.$t("months.february"),
        this.$t("months.March"),
        this.$t("months.April"),
        this.$t("months.May"),
        this.$t("months.June"),
        this.$t("months.July"),
        this.$t("months.August"),
        this.$t("months.September"),
        this.$t("months.October"),
        this.$t("months.November"),
        this.$t("months.December"),
      ],
      educationType: [
        this.$t("vacancyForm.educationTypes.high"),
        this.$t("vacancyForm.educationTypes.proffesioanl"),
      ],
      isWorkExperienceAdding: true,
      image: img,
      isLoading: false,
    };
  },
  methods: {
    addWorkExperience: function () {
      const { organization, position, workPeriod } = this.workExperienceFields;
      if (organization == "" || position == "" || workPeriod == "") {
        alert(this.$t("vacancyForm.enterExperience"));
      } else {
        this.form.workExperience.push({
          organization,
          position,
          workPeriod,
        });
        this.workExperienceFields = {
          organization: "",
          position: "",
          workPeriod: "",
        };
      }
    },
    addLanguage: function () {
      const { language, level } = this.languagesFields;
      if (language == "" || level == "") {
        alert(this.$t("vacancyForm.enterLanguage"));
      } else {
        this.form.languages.push({
          language,
          level,
        });
        this.languagesFields = {
          language: "",
          level: "",
        };
      }
    },
    deleteWorkExperience: function (index) {
      this.form.workExperience.splice(index, 1);
    },
    deleteLanguage: function (index) {
      this.form.languages.splice(index, 1);
    },
    async saveForm() {
      console.log(this.form);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success1",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      if (this.form.image) {
        this.isLoading = true;
        const resp = await axios.post(`${env.host}/upload/career/form`, {
          ...this.form,
          birthDate: `${this.form.birthDate.day} ${this.form.birthDate.month} ${this.form.birthDate.year}`,
        });
        if (resp.status < 400) {
          this.isLoading = false;
          swalWithBootstrapButtons.fire({
            text: this.$t("front.succes"),
          });
          window.location.reload();
        } else {
          this.isLoading = false;
          this.$swal({
            type: "error",
            text: this.$t("front.error"),
          });
          this.error = err;
        }
      } else {
        swalWithBootstrapButtons.fire({
          text: this.$t("front.chooseFile"),
        });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    fileReader(file) {
      const reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        that.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    previewFiles(e) {
      const selectedImage = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        this.$swal({
          icon: "error",
          text: this.$t("front.errorSize"),
        });
      }
      this.fileReader(selectedImage);
      this.getBase64(selectedImage).then((data) => {
        this.form.image = data;
      });
    },
  },
};
</script>


<style>
::v-deep.swal2-confirm,
.swal2-styled {
  background: red !important;
}
.primary {
  color: #7e5493 !important;
}
.btn-success1 {
  background-color: #7e54939f;
  color: white;
}

.btn-success1:hover {
  background-color: #7e5493;
  color: white;
}
::v-deep.btn-success1:focus {
  box-shadow: none !important;
}
::v-deep.btn-success1:focus {
  box-shadow: none !important;
}
.btn {
  border-radius: 0px;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 31em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 0px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
.btn.focus,
.btn:focus {
  /* outline: 0; */
  box-shadow: none;
}
</style>

<style scoped>
.form-control {
  border-radius: 0px;
}
.selectYes {
  border: 1px solid #ced4da;
  color: #4d4d4c;
}
.formContainer {
  width: 100%;

  max-width: 1400px;
  min-height: calc(100vh - 190px);
  margin: auto;
}
.lineStyle {
  width: 100%;
  margin: auto;
  padding: 0px !important;

  max-width: 1400px;
  border: 1px solid #4d4d4c;
}

label {
  text-transform: uppercase;
  font-family: dejavuBOLD;
  color: #4d4d4c;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  /* border-color: #7e5493; */
  /* padding: 5px 35px 5px 35px; */
  margin: auto;
  border: none;
  margin-bottom: 10px;
}

::v-deep.form-control:focus {
  /* background-color: none; */
  border-color: 2px solid #7e5493;
  outline: 0;
  border: 1px solid #7e5493;

  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}
/* adding english */
/* .div-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
} */
/* .button-div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
} */

/* .numbering {
  display: flex;
} */
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493bb;
  border-color: #005cbf00;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

@media all and (max-width: 1500px) {
  .formContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 767px) {
  .numbering {
    display: grid;
  }
  .numberingNeigh {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
